import React from 'react';
import { graphql } from 'gatsby';
import BookmarkContext from 'utils/BookmarkContext';
import useDynamicHeroImages from 'hooks/useDynamicHeroImages';
import ToolboxLayout from 'layouts/toolboxLayout';
import { stateList } from 'components/Form/utils/generalFormData';
import DownloadDetailHero from 'components/Page/Downloads/DetailPage/Hero';
import DownloadDetailMain from 'components/Page/Downloads/DetailPage/Main';

const DownloadDetailTemplate = (props) => {
  const data = props.data.contentfulDownloadableContent;
  const pageImages = useDynamicHeroImages();
  const backgroundImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'bg-main-White')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const splashPath = `/${
    data.downloadableContentType === 'Templates'
      ? 'templates'
      : data.downloadableContentType === 'Salary Pieces'
      ? 'salary'
      : 'licensing'
  }`;
  const stateAbbr = props.data.contentfulDownloadableContent.state;
  const state = stateList.find((singleState) => singleState.code === stateAbbr)?.value;
  const industryCrumbUrl = props.path.substr(0, props.path.lastIndexOf('/'));
  const industryCrumbTitle = data?.seo?.taxonomy?.industries?.[0];

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: data.downloadableContentType,
        icon: null,
        url: splashPath,
      },
      {
        title: industryCrumbTitle,
        icon: null,
        url:
          industryCrumbUrl !== splashPath
            ? industryCrumbUrl
            : `${splashPath}/${industryCrumbTitle.toLowerCase().replace(/\s+/g, '-')}`,
      },
      {
        title: state,
        icon: null,
        url: null,
      },
    ].filter((item) => !!item),
    absolute: true,
    transparent: true,
    theme: 'dark',
  };

  const bookmarkData = {
    type: 'download',
    url: props.path,
    title: data.title,
    image: data.featuredImage?.image,
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <DownloadDetailHero
          backgroundImg={backgroundImg}
          downloadableContentType={data.downloadableContentType}
          title={data.title}
          industry={data?.seo?.taxonomy?.industries}
          state={data.state}
        />
        <DownloadDetailMain data={data} />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default DownloadDetailTemplate;

export const DownloadDetailTemplateQuery = graphql`
  query DownloadDetailTemplateQuery($slug: String!, $downloadableContentType: String!) {
    contentfulDownloadableContent(
      seo: { pageUrl: { eq: $slug } }
      downloadableContentType: { eq: $downloadableContentType }
    ) {
      ...DownloadableContent
    }
  }
`;
