import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HeroContainer = styled.div`
  padding: 150px 28px 150px;
  background: ${(props) => `url(${props.backgroundImg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 500px -100px;

  .download-detail-hero--sub-title {
    font-weight: var(--font-weight-900);
    font-size: var(--font-size-16);
    line-height: var(--line-height-110);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--titan-blue-4);
    text-align: center;
    margin: 0 auto 20px;
  }

  .download-detail-hero--title {
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-44);
    font-weight: var(--font-weight-900);
    letter-spacing: var(--letter-spacing--0-05);
    color: var(--darkest);
    max-width: 910px;
  }

  .download-detail-hero--category {
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-13);
    line-height: var(--line-height-150);
    text-align: center;
    text-transform: uppercase;
    color: var(--grey-8);
    margin: 10px 0 0 0;

    img {
      margin-right: 8px;
      margin-top: -1px;
    }
  }

  @media (max-width: 767px) {
    padding: 140px 20px 140px;
    background-position: 200px 0;

    .download-detail-hero--title {
      font-size: var(--font-size-35);
    }
  }

  @media (max-width: 575px) {
    background-position: 0;
  }
`;
